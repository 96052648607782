import "react-circular-progressbar/dist/styles.css";
import AuthBasic from "../components/Auth";
import Header from "../components/Header";
import UserContext from "../components/UserContext";
import { useContext, useEffect } from "react";
import Footer from "../components/Footer";
import { getUser } from "../services/supabase";

export default function LoginPage(props) {
    const { user, supabase } = useContext(UserContext);

    useEffect(() => {
        console.log("user changed", user);
        if (user) {
            window.location.href = "/browse";
        }
    }, [user]);

    return (
        <div className="w-full h-screen flex flex-col dark:bg-gray-950">
            <div className="relative border-b border-gray-100 dark:border-gray-900">
                <div className="container mx-auto">
                    <Header
                        className="secondary"
                        {...props}
                        supabase={supabase}
                        user={user}
                        showCreate={false}
                        hideLogin={true}
                        title="Login"
                    />
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex space-x-4 mt-6 sm:mt-16 mx-4 sm:mx-32 md:mx-48 lg:mx-64 xl:mx-96 items-center justify-center">
                    <AuthBasic
                        supabase={supabase}
                        onSignedIn={(user) => {
                            console.log("signed in");
                            window.location.href = "/browse";
                        }}
                    />
                </div>
            </div>
            <div className="mt-32">
                <Footer />
            </div>
        </div>
    );
}

export async function getServerSideProps(context) {
    const user = await getUser(context.req);
    if (user) {
        return {
            redirect: {
                permanent: false,
                destination: "/browse",
            },
        };
    }
    return {
        props: {},
    };
}
